<template>
	<div class="q-px-md q-py-sm">
		<q-select
			class="col-11"
			v-model="field"
			name="Filter by date"
			label="Filter by date"
			color="primary"
			:options="view_flter_options"
			:option-label="(item) => item.value"
			@input="setViewFilter()"
		/>
		<div v-if="show_datepicker" class="flex justify-around">
			<q-date
				v-if="show_datepicker"
				v-model="date_from"
				mask="YYYY-MM-DD"
				class="q-my-sm"
				@input="chooseDates"
			/>
			<q-date
				v-if="date_from"
				v-model="date_to"
				mask="YYYY-MM-DD"
				class="q-my-sm"
				@input="chooseDates"
			/>
		</div>
	</div>
</template>

<script>
import moment from 'moment-timezone';
import ReportsAPI from '@/services/api/Reports.js';
import { date } from 'quasar'
export default {
	name: 'ReportsFilterDate',
	props:{
		selected_values_date: Object,
		selected_date_from: String,
		selected_date_to: String,
	},
	data(){
		return{
			view_flter_options: [
				{ value: 'All date ranges' },
				{ value: 'Custom dates' },
				{ value: 'Last 7 days' },
				{ value: 'Last 14 days' },
				{ value: 'Last 30 days' },
				{ value: 'Last 60 days' },
				{ value: 'Last 90 days' }
			],
			date_from: "",
			date_to: "",
			show_datepicker: false,
			field: {},
		}
	},
	methods:{
		async setViewFilter(){
			this.date_from = undefined;
			this.date_to = undefined;
			if(this.field['value'] === 'Custom dates'){
				this.show_datepicker = true;
				this.resetDate(false);
			}
			else if(this.field['value'].indexOf('Last') !== -1){
				this.show_datepicker = false;
				this.date_from = moment().subtract(this.field['value'].match(/\d+/)[0], 'days').format('YYYY-MM-DD');
				this.$emit("date-from-selected", {'type': this.field, 'value': this.date_from});
				this.resetDate(false);
			}else if(this.field['value'] === 'All date ranges'){
				this.show_datepicker = false;
				this.resetDate(true);
			}
			this.$emit('show-datepicker', this.show_datepicker)
			await ReportsAPI.updateUsersSessionFilters(this.field)
		},
		resetDate(val){
			this.date_from = "";
			this.date_to = "";
			this.$emit('reset-date', val);
		},
		chooseDates(){
			if (this.date_from > this.date_to){
				this.$q.notify({
					timeout: 6700,
					message: 'End date must be greater than start date.',
					actions: [
						{
							label: 'Dismiss',
							color: 'primary',
						},
					],
				})
				return
			}
			if (this.date_from){
				this.$emit("date-from-selected", {'type': this.field, 'value': this.date_from});
			}
			if (this.date_to){
				const date_to = date.addToDate(this.date_to, { hours: 23, minutes: 59, seconds: 59})
				this.$emit('date-to-selected', this.date_to);
			}
		}
	},
	async created(){
		if (this.selected_values_date){
			this.field = this.selected_values_date
		}
		this.setViewFilter(this.selected_values_date);
		this.date_from = this.selected_date_from ? this.selected_date_from : ''
		this.date_to = this.selected_date_to ? this.selected_date_to : ''
	},
}
</script>
