<template>
	<q-card style="width: 700px; max-width: 80vw">
		<q-card-section>
			<div class="text-h6">Copy template</div>
		</q-card-section>
		<q-card-section class="q-py-none">
			<q-item tag="label">
				<q-item-section>
					<q-item-label class="text-left">Copy to {{test_scenario_name}} templates</q-item-label>
				</q-item-section>
				<q-item-section avatar>
					<q-radio v-model="copy_as_real" :val="false" color='primary'/>
				</q-item-section>
			</q-item>
			<q-item v-if="!copy_as_real && subset_scenario_name" tag="label">
				<q-item-section>
					<q-item-label class="text-left">Copy to {{subset_scenario_name}} templates</q-item-label>
				</q-item-section>
				<q-item-section avatar>
					<q-checkbox v-model="copy_as_test_subset" color="primary" />
				</q-item-section>
			</q-item>
			<q-item tag="label">
				<q-item-section>
					<q-item-label class="text-left">Copy to {{real_scenario_name}} templates</q-item-label>
				</q-item-section>
				<q-item-section avatar>
					<q-radio v-model="copy_as_real" :val="true" color='primary'/>
				</q-item-section>
			</q-item>
		</q-card-section>
		<q-card-actions class="justify-end">
			<q-btn color="primary" flat label="Cancel" @click="$emit('cancel-copy')"/>
			<q-btn color="primary" flat label="Copy" @click="copyScenario(scenario_id)" />
		</q-card-actions>
	</q-card>
</template>
<script>
export default {
	name: 'CopyScenario',
	props:{
		scenario_id: Number,
		test_scenario_name: String,
		real_scenario_name: String,
		subset_scenario_name: String,
	},
	data(){
		return{
			copy_as_real: false,
			copy_as_test_subset: false,
		}
	},
	methods:{
		copyScenario(scenario_id){
			this.$emit('copy-scenario', scenario_id, this.copy_as_real, this.copy_as_test_subset)
		},
	},
}
</script>