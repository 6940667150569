<template>
	<div
		v-if="show_flags &&
			(is_invocation || (!is_invocation && (this.active_tab == 'test' || this.active_tab == 'all')))"
	>
		<q-item tag="label" class="items-center">
			<q-item-section>
				<q-item-label class="text-left">
					<span v-if="is_invocation">Filter by Invocation Method</span>
					<span v-else>
						Filter by {{current_CG.customer_group.session_type_name.test_scenario_name}} type:
					</span>
				</q-item-label>
			</q-item-section>
			<q-item-section avatar>
				<q-toggle
					v-model="use_filter"
				/>
			</q-item-section>
		</q-item>
		<q-select
			v-if="use_filter"
			v-model="selected"
			class="q-px-md q-py-sm"
			multiple
			:label="is_invocation ? 'Select invocation method' : 'Select type'"
			use-chips
			:options="flags"
			:option-label="(item) => item.label"
			@input="filterSessionsByInvocationMethod()"
		/>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
	name: 'FilterByMethod',
	data(){
		return {
			show_flags: false,
			use_filter: false,
			flags: [],
			selected: [],
			has_phone_invoke_permission: false,
			has_email_invoke_permission: false,
			has_incident_manager_permission: false,
		}
	},
	props: {
		selected_values: Array,
		is_invocation: Boolean,
		active_tab: String,
	},
	computed:{
		...mapGetters(['current_CG']),
	},
	methods:{
		initFlagsInvocation(){
			let flags = []
			flags.push({'label':'Dashboard', 'type':'is_dashboard'})

			if (this.has_phone_invoke_permission) {
				flags.push({'label':'Phone Invoke',  'type':'is_phone_invoke'})
			}
			if (this.has_email_invoke_permission) {
				flags.push({'label':'Email Invoke', 'type':'is_email_invoke'})
			}
			if (this.has_incident_manager_permission) {
				flags.push({'label':'Incident Manager', 'type':'is_incident_manager'})
			}
			return flags;
		},
		initFlagsSubset(){
			let flags = [
				{
					'label': this.current_CG.customer_group.session_type_name.test_scenario_name,
					'type':'is_test'
				},
				{
					'label': this.current_CG.customer_group.session_type_name.subset_scenario_name,
					'type':'is_subset'
				}
			]
			return flags;
		},
		filterSessionsByInvocationMethod(){
			if(this.is_invocation){
				this.$emit('filter-sessions-by-method', 'invocation', this.selected)
			}else{
				this.$emit('filter-sessions-by-method', 'subset', this.selected)
			}
		}
	},
	created(){
		this.use_filter = this.selected_values.length ? true : false
		if (this.is_invocation){
			this.has_phone_invoke_permission = this.current_CG.root_customer_group_permissions.indexOf('Phone invoke') >= 0;
			this.has_email_invoke_permission = this.current_CG.root_customer_group_permissions.indexOf('Email invoke') >= 0
			this.has_incident_manager_permission = this.current_CG.root_customer_group_permissions
				.filter(permission => permission.indexOf('Incident Manager') >= 0)
				.length > 0;
			this.show_flags = this.has_phone_invoke_permission || this.has_incident_manager_permission || this.has_email_invoke_permission;
			this.selected = this.selected_values
			this.flags = this.initFlagsInvocation()
		}else{
			this.show_flags = this.current_CG.root_customer_group_permissions.indexOf('Allow Subset') >= 0;
			this.selected = this.selected_values
			this.flags = this.initFlagsSubset()
		}

	},
	watch: {
		use_filter: function(){
			if (!this.use_filter){
				this.selected = []
			}
			if (this.is_invocation){
				this.$emit('filter-sessions-by-method', 'invocation', this.selected)
			}else{
				this.$emit('filter-sessions-by-method', 'subset', this.selected)
			}
		}
	}
}
</script>
