<template>
	<q-card class="q-px-md" style="width: 700px; max-width: 80vw">
		<q-card-section class="q-pb-none">
			<div class="text-h6">Filter 
				<span v-if="message_reports">message reports</span>
				<span v-else-if="message_list">messages</span>
				<span v-else>scheduled messages</span>
			</div>
		</q-card-section>
		<ReportsFilterDate
			v-if="message_reports"
			:selected_values_date="selected_values_date"
			:selected_date_to="selected_date_to"
			:selected_date_from="selected_date_from"
			@date-from-selected="dateFromSelected"
			@date-to-selected="dateToSelected"
			@show-datepicker="showDatepicker"
			@reset-date="resetDate"
		/>
		<FilterByMethod
			v-if="message_reports || message_list"
			:is_invocation="true"
			:selected_values="selected_values_invocation"
			@filter-sessions-by-method="setMethodFilter"
		/>
		<FilterByMethod
			:is_invocation="false"
			:active_tab="active_tab"
			:selected_values="selected_values_subset"
			@filter-sessions-by-method="setMethodFilter"
		/>
		<q-card-actions class="justify-end">
			<q-btn color="primary" flat label="Cancel" @click="$emit('cancel')"/>
			<q-btn color="primary" flat label="Filter" @click="applyFilters" />
		</q-card-actions>
	</q-card>
</template>
<script>
import ReportsFilterDate from '@/components/scenarios/message_reports/reports/ReportsFilterDate.vue';
import FilterByMethod from '@/components/scenarios/utils/FilterByMethod.vue';
export default {
	name: "MessageFilters",
	components: {
		ReportsFilterDate,
		FilterByMethod,
	},
	data() {
		return {
			type: '',
			invocation_values: [],
			subset_values: [],
			date_from_selected: '',
			date_to_selected: '',
			show_datepicker: false,
			reset_date: false,
			ordering: '',
			order_rule: {},
		}
	},
	props: {
		selected_values_date: Object,
		selected_date_from: String,
		selected_date_to: String,
		selected_values_invocation: Array,
		selected_values_subset: Array,
		active_tab: String,
		message_reports: Boolean,
		message_list: Boolean,
		order_type: String,
		order_by: Object,
		order_by_options: Array,
	},
	methods: {
		setMethodFilter(type, selected){
			if (type == 'invocation'){
				this.invocation_values = selected
			}else{
				this.subset_values = selected
			}
			this.type = type
		},
		applyFilters(){
			this.$emit('filter-sessions-by-method', 'invocation', this.invocation_values)
			this.$emit('filter-sessions-by-method', 'subset', this.subset_values)
			this.$emit('date-from-selected', this.date_from_selected)
			if (this.date_to_selected){
				this.$emit('date-to-selected', this.date_to_selected)
			}
			this.$emit('show-datepicker', this.show_datepicker)
			if (this.reset_date){
				this.$emit('reset-date')
			}
			if (Object.keys(this.order_rule).length ){
				this.$emit('change-order-by-rule', this.order_rule)
			}
			if (this.ordering){
				this.$emit("change-order-type", this.ordering)
			}
			if (!this.ordering && !Object.keys(this.order_rule).length){
				this.$emit('get-filtered-data')
			}
			this.$emit('cancel')
		},
		dateFromSelected(new_val){
			this.date_from_selected = new_val
		},
		dateToSelected(new_val){
			this.date_to_selected = new_val
		},
		showDatepicker(value){
			this.show_datepicker = value
		},
		resetDate(val){
			this.reset_date = val
		},
		changeOrderByRule(order_rule){
			this.order_rule = order_rule
		},
		changeOrderType(order_type){
			this.ordering = order_type
		},
	},
}
</script>