<template>
	<div :class="$q.screen.gt.xs ? 'q-pa-xl' : 'q-pa-sm'">
		<h1 class="text-h5 q-pb-md q-my-md text-left fields-tab-header">
			Send Message
		</h1>
		<MessageListScenarios />
	</div>
</template>

<script>

import MessageListScenarios from '@/components/scenarios/message_scenarios_list/MessageListScenarios.vue';

export default {
	name: "SendMessage",
	meta: {
		title: 'Alert Cascade - Send Message'
	},
	components: {
		MessageListScenarios
	},
};
</script>
