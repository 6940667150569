<template>
	<q-card v-if="show_delete_dialog">
		<q-card-section>
			<div>
				<span>Are you sure that you want to delete <strong>{{scenario.name}}</strong>?</span>
			</div>
		</q-card-section>
		<q-card-actions align="right">
			<q-btn flat label="Cancel" color="primary" v-close-popup />
			<q-btn
				flat
				label="Delete"
				color="primary"
				@click="checkScheduledSessions(scenario.id)"
			/>
		</q-card-actions>
	</q-card>
	<q-card v-else-if="show_warning_dialog">
		<q-card-section>
			<div>
				<span>There are scheduled sessions for <strong>{{scenario.name}}</strong>.
				Do you wish	to continue?</span>
			</div>
		</q-card-section>
		<q-card-actions align="right">
			<q-btn flat label="Cancel" color="primary" v-close-popup />
			<q-btn
				flat
				label="Delete"
				color="primary"
				@click="deleteScenario(scenario.id)"
			/>
		</q-card-actions>
	</q-card>
</template>
<script>
import ListApi from '@/services/api/ListScenarios.js';
export default {
	name: 'DeleteScenario',
	components: {
		ListApi,
	},
	props:{
		scenario: Object,
	},
	data() {
		return {
			show_delete_dialog: true,
			show_warning_dialog: false,
		}
	},
	methods:{
		async checkScheduledSessions(scenario_id){
			this.$q.loading.show({
				delay: 400,
			})
			this.show_delete_dialog = false
			let response = await ListApi.checkScheduledSession(scenario_id);
			if (response.has_scheduled_session){
				this.show_warning_dialog = true
				this.$q.loading.hide()
				return
			}
			this.$q.loading.hide()
			this.$emit('delete-scenario', scenario_id)
		},
		deleteScenario(scenario_id){
			this.show_delete_dialog = false
			this.show_warning_dialog = false
			this.$emit('delete-scenario', scenario_id)
		},
	},
}
</script>
