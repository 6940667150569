import { render, staticRenderFns } from "./CopyScenario.vue?vue&type=template&id=ad2748f6&"
import script from "./CopyScenario.vue?vue&type=script&lang=js&"
export * from "./CopyScenario.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QRadio from 'quasar/src/components/radio/QRadio.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QCard,QCardSection,QItem,QItemSection,QItemLabel,QRadio,QCheckbox,QCardActions,QBtn});
