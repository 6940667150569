<template>
	<div>
		<q-btn
			v-if="scenario.is_blocked && logged_user.customer_permission == 'ADMIN' && is_message_template"
			icon="o_lock_open"
			color="dark"
			flat
			round
			@click="scenarioUnblock(scenario.id)"
		>
			<q-tooltip anchor="top middle" :delay="500">
				Unblock Scenario
			</q-tooltip>
		</q-btn>
		<q-btn
			v-if="!is_schedule_message && (can_edit_content || can_edit_recipients)"
			flat
			round
			color="dark"
			:to="{ name: rerouting_name, params: {id: scenario.id }}"
			@click.native="set_active_item(202)"
			icon="o_visibility"
		>
			<q-tooltip anchor="top middle" :delay="500">
				Edit Message
			</q-tooltip>
		</q-btn>
		<q-btn
			v-if="!is_schedule_message && logged_user.customer_permission !== 'SENDER'"
			flat
			round
			color="dark"
			:to="{ name: 'Edit Message Content', params: {id: scenario.id }}"
			@click.native="set_active_item(202)"
			icon="o_edit"
		>
			<q-tooltip anchor="top middle" :delay="500">
				Edit Message
			</q-tooltip>
		</q-btn>
		<q-btn
			v-if="!current_CG.customer_group.sandbox && !is_message_template && !is_schedule_message"
			:to="{ name: 'Edit Message Finish', params: {id: scenario.id }}"
			@click.native="set_active_item(202)"
			flat
			round
			color="dark"
			icon="o_send"
		>
			<q-tooltip anchor="top middle" :delay="500">
				Send Message
			</q-tooltip>
		</q-btn>
		<q-btn
			v-if="is_message_template"
			@click="scenarioCopyModal()"
			flat
			round
			color="dark"
			icon="o_content_copy"
		>
			<q-tooltip anchor="top middle" :delay="500">
				Copy
			</q-tooltip>
		</q-btn>

		<q-btn
			v-if="is_message_template"
			@click="scenarioDeleteModal()"
			flat
			round
			color="dark"
			icon="o_delete"
		>
		<q-tooltip anchor="top middle" :delay="500">
			Delete
		</q-tooltip>
		</q-btn>

		<q-btn
			v-if="is_message_template || is_schedule_message"
			@click="scheduleMessageModal()"
			flat
			round
			color="dark"
			icon="o_calendar_month"
		>
			<q-tooltip anchor="top middle" :delay="500">
				Schedule
			</q-tooltip>
		</q-btn>

		<q-dialog v-model="show_copy_modal">
			<CopyScenario
				:scenario_id="scenario.id"
				:test_scenario_name="test_scenario_name"
				:real_scenario_name="real_scenario_name"
				:subset_scenario_name="subset_scenario_name"
				@cancel-copy="cancelCopy"
				@copy-scenario="copyScenario"
			/>
		</q-dialog>
		<q-dialog v-model="show_delete_modal">
			<DeleteScenario
				v-if="show_delete_modal"
				:scenario="scenario"
				:test_scenario_name="test_scenario_name"
				:real_scenario_name="real_scenario_name"
				@delete-scenario="deleteScenario"
				@cancel="cancelDelete"
			/>
		</q-dialog>
		<q-dialog v-model="show_schedule_modal">
			<ScheduleModal
				v-if="show_schedule_modal"
				:active_tab="active_tab"
				:session_id="null"
				:scenario_name="scenario.name"
				:recurrent_session_id="null"
				:preferred_timezone="logged_user.preferred_timezone"
				:show_recurrent_message_fields='true'
				:is_schedule_message='true'
				@schedule-message="scheduleScenario"
				@update-recurrent-data="updateRecurrentData"
				@update-is-recurrent='updateIsRecurrent'
				@close="show_schedule_modal=false"
				@update-values="updateValues"
			/>
		</q-dialog>
		<q-dialog v-model="has_messages">
			<CheckDatesMessage
				:messages="messages"
				@close="closeMessages()"
			/>
		</q-dialog>
	</div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import DeleteScenario from '@/components/scenarios/message_reports/message_templates/DeleteScenario.vue'
import CopyScenario from '@/components/scenarios/message_reports/message_templates/CopyScenario.vue'
import ScheduleModal from '@/components/scenarios/calendar_reports/ScheduleModal.vue';
import MessageFormsAPI from '@/services/api/MessageForm.js';
import CheckDatesMessage from '@/components/scenarios/calendar_reports/CheckDatesMessage.vue';

export default {
	name:"Scenario",
	components: {
		DeleteScenario,
		CopyScenario,
		ScheduleModal,
		MessageFormsAPI,
		CheckDatesMessage,
	},
	props:{
		scenario: Object,
		scenario_id: Number,
		is_message_template: Boolean,
		is_schedule_message: Boolean,
		active_tab: String,
	},
	data(){
		return{
			show_delete_modal: false,
			show_copy_modal: false,
			show_schedule_modal: false,
			message: {},
			test_scenario_name: '',
			real_scenario_name: '',
			subset_scenario_name: '',
			check_holiday: false,
			reschedule_holiday: false,
			check_weekend: false,
			reschedule_weekend: false,
			selected_country: '',
			selected_subdivision: '',
			holiday_list: [],
			messages: [],
			has_messages: false,
			rerouting_name: '',
			can_edit_content: false,
			can_edit_recipients: false,
		}
	},
	computed:{
		...mapGetters(['logged_user', 'current_CG', 'tabs_with_errors'])
	},
	methods:{
		...mapActions(['updateIsScheduleMessageRunning', 'set_active_item']),

		scenarioCopyModal() {
			this.show_copy_modal = true;
		},
		copyScenario(scenario_id, copy_is_real, copy_as_subset){
			this.$emit('copy-scenario', scenario_id, copy_is_real, copy_as_subset)
			this.show_copy_modal = false;
		},
		cancelCopy(){
			this.show_copy_modal = false;
		},
		scenarioDeleteModal() {
			this.show_delete_modal = true;
		},
		deleteScenario(scenario_id) {
			this.$emit('delete-scenario', scenario_id);
			this.show_delete_modal = false;
		},
		cancelDelete(){
			this.show_delete_modal = false;
		},
		scheduleMessageModal() {
			this.show_schedule_modal = true;
		},
		scenarioUnblock(scenario_id){
			this.$emit('unblock-scenario', scenario_id);
		},
		scheduleScenario(scheduled_datetime) {
			this.show_schedule_modal = false;
			this.scheduled_datetime = scheduled_datetime;
			this.saveScenario();
		},
		updateRecurrentData(obj) {
			this.recurrent_data = obj;
		},
		updateIsRecurrent(val) {
			this.is_recurrent = val;
		},
		updateValues({type, value}){
			this[type] = value
		},
		closeMessages(){
			this.has_messages = false
			setTimeout(() => {
				this.set_active_item(204);
				this.$router.push({ name: 'Message Calendar' });
			}, 500);
		},
		async saveScenario() {
			let scenario_data = this.createFinalData();
			let response = {};
			try {
				response = await MessageFormsAPI.scheduleScenario(scenario_data);
				this.updateIsScheduleMessageRunning(true);
				this.check_session_init_interval = setInterval(() => {
					this.checkSessionInitResult(response.task_id);
				}, 1000);
			} catch (error) {
				let error_message = "";
				for (let key in error.data) {
					error_message += error.data[key];
				}
				this.$emit("get-message", { 'type': 'error', 'text': error_message })
			}
		},
		async checkSessionInitResult(task_id) {
			try {
				const response = await MessageFormsAPI.getRunResult(task_id);
				if (response['session_id'] || response['success']) {
					clearInterval(this.check_session_init_interval);
					this.updateIsScheduleMessageRunning(false);
					if (response['messages']){
						this.has_messages = true
						this.messages = response['messages']
					}else{
						this.has_messages = false
						this.message = {
							'type': 'success',
							'text': 'Your message was successfully scheduled.'
						}
						this.$emit("get-message", this.message)
						setTimeout(() => {
							this.set_active_item(204);
							this.$router.push({ name: 'Message Calendar' });
						}, 2000);
					}
				}
			} catch (error) {
				clearInterval(this.check_session_init_interval);
				this.updateIsScheduleMessageRunning(false);
				setTimeout(() => { this.check_session_init_interval = null; }, 500);

				if (typeof (error.data) == 'string') {
					this.$emit("get-message", { 'type': 'error', 'text': error.data })
				} else {
					let error_message = "";
					for (let key in error.data) {
						error_message += error.data[key];
					}
					this.$emit("get-message", { 'type': 'error', 'text': error_message })
				}
			}
		},
		processError(error) {
			const error_tabs = this.tabs_with_errors.join(', ').toUpperCase();
			const message = {
				text: `There are errors in the following tabs: ${error_tabs}. Please correct them.`,
				type: 'error'
			};
		},
		createFinalData() {
			let scenario_data = {};
			scenario_data['scheduled_datetime'] = this.scheduled_datetime;
			scenario_data['scenario_id'] = this.scenario.id
			scenario_data['schedule'] = true
			scenario_data['recurrent'] = this.is_recurrent
			scenario_data['recurrent_data'] = this.recurrent_data
			scenario_data['check_holiday'] = this.check_holiday
			scenario_data['reschedule_holiday'] = this.reschedule_holiday
			scenario_data['check_weekend'] = this.check_weekend
			scenario_data['reschedule_weekend'] = this.reschedule_weekend
			scenario_data['selected_country'] = this.selected_country
			scenario_data['selected_subdivision'] = this.selected_subdivision
			scenario_data['ignore_holiday_list'] = this.holiday_list

			return scenario_data;
		},
	},
	created(){
		if(this.logged_user.customer_permission === 'SENDER') {
			this.can_edit_recipients = this.current_CG.root_customer_group_permissions.includes('Allow Senders to edit recipients');
			this.can_edit_content = this.current_CG.root_customer_group_permissions.includes('Allow Senders to edit content');
			if(this.can_edit_content) {
				this.rerouting_name = 'Edit Message Content'
			} else if(this.can_edit_recipients) {
				this.rerouting_name = 'Edit Message Recipients'
			} else {
				this.rerouting_name = 'Edit Message Finish'
			}
		}else{
			this.rerouting_name = 'Edit Message Content'
		}
		this.test_scenario_name = this.current_CG.customer_group.session_type_name.test_scenario_name
		this.real_scenario_name = this.current_CG.customer_group.session_type_name.real_scenario_name
		if (this.current_CG.root_customer_group_permissions.includes('Allow Subset')){
			this.subset_scenario_name = this.current_CG.customer_group.session_type_name.subset_scenario_name
		}
	}
}
</script>
