import axios from 'axios';
import utils from '../utils.js'

export default {
	async listScenarios(data){
		const flags = !data.flags ? '' : `&flags=${data.flags}`;
		const subset = !data.subset_flags ? '' : `&subset=${data.subset_flags}`;
		try{
			const response = await axios.get(
				`/api/scenario/?` + 'include_sessions=' + data.include_sessions + '&include_scheduled=' + 
				data.include_scheduled + '&page=' + data.page_number + '&page_size=' + data.items_per_page + '&active_tab=' + 
				data.active_tab + '&search_by_name=' + data.search_by_name + flags + subset
			);
			return response.data;
		}catch(error){
			return utils.getError(error)
		}
	},
	async deleteScenario(scenario_id){
		try{
			const response = await axios.delete(`/api/scenario/${scenario_id}/`)
			return response.data;
		}catch(error){
			return utils.getError(error)
		}
	},
	async checkScheduledSession(scenario_id){
		try{
			const response = await axios.get(`/api/scenario/check_scheduled_session/${scenario_id}/`)
			return response.data;
		}catch(error){
			return utils.getError(error)
		}
	},
	async checkScheduledSessions(active_tab){
		try{
			const response = await axios.get(`/api/scenario/check_scheduled_sessions/${active_tab}/`)
			return response.data;
		}catch(error){
			return utils.getError(error)
		}
	},
	async deleteAllScenarios(active_tab){
		try{
			const response = await axios.delete(`/api/scenario/delete_all/${active_tab}/`)
			return response.data;
		}catch(error){
			return utils.getError(error)
		}
	},
	async copyScenario(active_tab, copy_is_real, scenario_id, copy_as_subset){
		try{
			const response = await axios.put(`/api/scenario/?` + 'active_tab=' + active_tab  + '&copy_is_real=' + copy_is_real + '&id=' + scenario_id + '&copy_as_subset=' + copy_as_subset)
			return response.data;
		}catch(error){
			return utils.getError(error)
		}
	},
	async unblockScenario(scenario_id){
		try{
			const response = await axios.post(`/api/scenario/unblock_scenario/?` + 'scenario_id=' + scenario_id)
			return response.data;
		}catch(error){
			return utils.getError(error)
		}
	}
}