import axios from 'axios';
export default {
	async updateUsersSessionFilters(data){
		try{
			const response = await axios.patch(`/api/user/update_scenario_filtering/`, data);
			return response.data;
		}catch(error){
			return {
				error: true,
				data: error.response.data,
				statusText: error.response.statusText,
				statusCode: error.response.status,
			}
		}
	},
	async updateUserScenarioOrdering(data){
		try{
			const response = await axios.patch(`/api/user/`, data)
			return response.data
		}catch(error){
			return {
				error: true,
				data: error.response.data,
				statusText: error.response.statusText,
				statusCode: error.response.status,
			}
		}
	},
	async generateSummaryExport(data, active_tab){
		try{
			let payload = {
				'data': data,
				'active_tab': active_tab
			}
			const response = await axios.put('/api/generate_filtered_sessions_report/', payload);
			return response.data;
		}catch(error){
			return {
				error: true,
				data: error.response.data,
				statusText: error.response.statusText,
				statusCode: error.response.status,
			}
		}
	}
}