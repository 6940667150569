<template>
	<q-item>
		<q-radio
			v-for='(tab, index) in tabs'
			:key="index"
			v-model="active_tab"
			:val='tab.selector'
			:label='tab.name.toUpperCase()'
			@input="select_tab"
		/>
	</q-item>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
	name: 'Tabs',
	data () {
		return {
			active_tab: 'test',
			selected_tab: '',
		}
	},
	props:{
		changed_tab: String,
	},
	computed:{
		...mapGetters(['current_CG']),
		tabs: function () {
			return [
				{"name": this.current_CG.customer_group.session_type_name.test_scenario_name, "selector": 'test'},
				{"name": this.current_CG.customer_group.session_type_name.real_scenario_name, "selector": 'real'},
				{"name": 'all', "selector": 'all'},
			]
		}
	},
	watch:{
		changed_tab: function(){
			if (this.changed_tab != this.active_tab){
				this.active_tab = this.changed_tab
			}
		}
	},
	methods:{
		select_tab(){
			this.$emit('open-tab', this.active_tab)
		}
	},
}
</script>
