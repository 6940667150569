<template>
	<div>
		<SuccessErrorMessage :message="message" />
		<q-table
			:data="scenarios"
			:columns="$q.screen.gt.xs ? columns : columns_smaller_screen"
			:max="number_of_pages"
			:rows-per-page-options="[5, 10, 20, 50, 100, 0]"
			:pagination.sync="pagination"
			binary-state-sort
			:loading="loading"
			@request="onRequest"
		>
			<template v-slot:top>
				<div v-if='has_filters' class="flex flex-wrap items-center q-mb-md">
					<q-btn icon="filter_list" @click="show_filters=true" round flat>
						<q-tooltip anchor="top middle" :delay="500">
							Filter
						</q-tooltip>
					</q-btn>
					<span v-for="selected_value_in in selected_values_invocation" :key="selected_value_in.label">
						<q-chip
							removable
							outline
							color="primary"
							:label="selected_value_in.label"
							@remove="removeItem(selected_value_in, 'invocation')"
						/>
					</span>
					<span v-for="selected_value_sub in selected_values_subset" :key="selected_value_sub.label">
						<q-chip
							v-if="active_tab != 'real'"
							removable
							outline
							color="primary"
							:label="selected_value_sub.label"
							@remove="removeItem(selected_value_sub, 'subset')"
						/>
					</span>
				</div>
				<div class="row full-width">
					<div class="col-md-6 col-12 items-baseline">
						<q-input
							v-model="search_by_name"
							label="Search by message name"
							outlined
							:hint="search_by_name && search_by_name.length < 3 ? 'Enter at least 3 characters' : ''"
							@input="searchByName"
						>
							<template v-slot:append>
								<q-icon name="search" />
							</template>
						</q-input>
					</div>
					<div class="col-md-6 col-12 flex justify-end items-center">
						<Tabs
							:changed_tab="changed_tab"
							@open-tab="openTab"
						/>
					</div>
				</div>
			</template>
			<template v-slot:body-cell-actions="props" style="max-width:100px;">
				<q-td :props="props">
					<Scenario
						:scenario='props.row'
						:is_message_template="is_message_template"
						:is_schedule_message='is_schedule_message'
						:active_tab='active_tab'
						@copy-scenario="copyScenario"
						@delete-scenario="deleteScenario"
						@unblock-scenario='unblockScenario'
						@get-message='getMessage'
					/>
				</q-td>
			</template>
			<template v-slot:body-cell-name="props">
				<q-td :props="props">
					{{props.value}}
					<q-tooltip anchor="top middle" :delay="500">
						{{props.value}}
					</q-tooltip>
				</q-td>
			</template>
			<template v-slot:pagination>
				<span class="row items-center justify-end">
					<q-pagination
						v-model="page_number"
						:max="number_of_pages"
						:max-pages="5"
						size="md"
						color="grey-8"
						active-color="primary"
						direction-links
						boundary-links
						@click="changePage(page_number)"
					/>
				</span>
			</template>
		</q-table>
		<q-dialog v-model="show_filters">
			<MessageFilters
				:selected_values_invocation="selected_values_invocation"
				:selected_values_subset="selected_values_subset"
				:active_tab="active_tab"
				:message_list="true"
				@change-order-type="changeOrderType"
				@filter-sessions-by-method="filterScenariosByMethod"
				@get-filtered-data="getAllScenarios"
				@cancel="show_filters=false"
			/>
		</q-dialog>
		<q-dialog v-model="show_delete_dialog">
			<q-card>
				<q-card-section class="text-center">
					<span>
						Are you sure you want to delete
						<span v-if="active_tab == 'all'"><b> ALL </b></span>
						<span v-else-if="active_tab == 'test'">
							all <b>{{ current_CG.customer_group.session_type_name.test_scenario_name.toUpperCase() }}</b> 
						</span>
						<span v-else>
							all <b>{{ current_CG.customer_group.session_type_name.real_scenario_name.toUpperCase() }}</b>
						</span>
						templates? 
					</span>
				</q-card-section>
				<q-card-actions align="right">
					<q-btn flat label="Cancel" color="primary" v-close-popup />
					<q-btn
						flat
						label="Delete"
						color="primary"
						@click="checkScheduledSessions"
					/>
				</q-card-actions>
			</q-card>
		</q-dialog>
		<q-dialog v-model="show_warning">
			<q-card>
				<q-card-section class="text-center">
					<span>
						There are scheduled messages for some of the templates you are trying to delete. 
						Do you wish	to continue?
					</span>
				</q-card-section>
				<q-card-actions align="right">
					<q-btn flat label="Cancel" color="primary" v-close-popup />
					<q-btn
						flat
						label="Continue"
						color="primary"
						@click="deleteAllTemplates"
					/>
				</q-card-actions>
			</q-card>
		</q-dialog>
		<div v-if="scenarios.length && is_message_template" class="field-fab-wrapper">
			<q-btn
				v-if="$q.screen.gt.sm"
				rounded
				padding="md lg"
				class="q-mt-md"
				color="primary"
				icon-right="delete"
				label='Delete all'
				@click="show_delete_dialog=true"
			/>
			<q-btn
				v-else
				round
				class="q-mt-md"
				icon="delete"
				color="primary"
				size="lg"
				@click.native="show_delete_dialog=true"
			>
				<q-tooltip anchor="top middle" :delay="500">
					Delete all
				</q-tooltip>
			</q-btn>
		</div>
	</div>
</template>

<script>
import ListApi from '@/services/api/ListScenarios.js';
import Tabs from '@/components/scenarios/utils/Tabs.vue';
import MessageFilters from '@/components/utils/Filters.vue';
import ReportsAPI from '@/services/api/Reports.js';
import SuccessErrorMessage from "@/components/scenarios/calendar_reports/SuccessErrorMessage.vue";
import { mapGetters } from 'vuex';
import Scenario from '@/components/scenarios/message_scenarios_list/Scenario.vue';
import moment from 'moment-timezone';

export default {
	name: "MessageListScenarios",
	components: {
		Tabs,
		MessageFilters,
		ReportsAPI,
		ListApi,
		SuccessErrorMessage,
		Scenario
	},
	props: {
		is_message_template: Boolean,
		is_schedule_message: Boolean,
	},
	data() {
		return {
			scenarios: [],
			active_tab: 'test',
			page_number: 1,
			items_per_page: 20,
			scenarios_ready: false,
			count: 0,
			has_data: true,
			order_type: "ascending",
			order_by: {},
			flags: "",
			subset_flags: "",
			include_scheduled: false,
			include_sessions: false,
			order_by_options_scenario: {
				'name': {'option_value': 'name', 'option_text': 'Name'},
				'created_at': {'option_value': 'created_at', 'option_text': 'Date created'},
				'message_id': {'option_value': 'message_id', 'option_text': 'Message ID'}
			},
			message: {},
			pagination: {
				rowsPerPage: 20,
				rowsNumber: 0,
				sortBy: 'created_at',
				descending: true,
				page: 1,
			},
			show_filters: false,
			selected_values_invocation: [],
			selected_values_subset: [],
			columns_smaller_screen: [
				{
					name: 'name',
					field: 'name',
					align: 'left',
					label: 'Name',
					sortable: true,
					classes: 'ellipsis',
					style: 'max-width: 150px',
					headerStyle: 'max-width: 150px',
				},
				{
					name: 'actions',
					align: 'right',
					label: 'Actions',
					classes: 'ellipsis',
					style: 'width: 110px',
					headerStyle: 'width: 110px',
				},
			],
			columns: [
				{
					name: 'message_id',
					field: 'message_id',
					align: 'left',
					label: 'Message ID',
					sortable: true,
					classes: 'ellipsis',
					style: 'width: 115px',
					headerStyle: 'width: 115px',
				},
				{
					name: 'name',
					field: 'name',
					align: 'left',
					label: 'Name',
					sortable: true,
					classes: 'ellipsis',
					style: 'max-width: 150px',
					headerStyle: 'max-width: 150px',
				},
				{
					name: 'created_at',
					align: 'left',
					label: 'Created At',
					field: obj => {
						return moment.utc(obj.created_at).tz(this.preferred_timezone).format('DD/MM/YYYY HH:mm')
					},
					sortable: true,
					classes: 'ellipsis',
					style: 'width: 135px',
					headerStyle: 'width: 135px',
				},
				{
					name: 'actions',
					align: 'center',
					label: 'Actions',
					classes: 'ellipsis',
					style: 'width: 110px',
					headerStyle: 'width: 110px',
				},
			],
			loading: false,
			search_by_name: '',
			has_filters: false,
			changed_tab: '',
			show_delete_dialog: false,
			show_warning: false,
		};
	},
	computed: {
		...mapGetters(['is_schedule_message_running', 'logged_user', 'current_CG']),
		number_of_pages: function () {
			return Math.ceil(this.count / this.items_per_page);
		}
	},
	methods: {
		async openTab(active_tab) {
			this.page_number = 1;
			this.active_tab = active_tab;
			await this.getAllScenarios();
		},
		async getAllScenarios() {
			this.loading = true
			let data = {
				'page_number': this.page_number,
				'items_per_page': this.items_per_page,
				'message_sending': 'message_sending',
				'active_tab': this.active_tab,
				'search_by_name': this.search_by_name,
				'flags': this.flags,
				'include_sessions': this.include_sessions,
				'include_scheduled': this.include_scheduled,
				'subset_flags': this.subset_flags,
			}
			try {
				const response = await ListApi.listScenarios(data)
				this.scenarios = response.results.scenarios;
				this.count = response.count;
				this.has_data = response.count > 0;
				this.order_by = response.results.order_by_rule;
				if (!response.results.scenarios_order_type) {
					this.order_type = 'ascending';
				} else {
					this.order_type = response.results.scenarios_order_type;
				}
			} catch (error) {
				if (error.data.detail == "Invalid page.") {
					this.page_number = this.page_number - 1;
					this.changePage(this.page_number);
					this.loading = false
					return;
				}
				this.message = {
					'type': 'error',
					'text': error.data
				};
			}
			this.loading = false
		},
		async changePage(page_number) {
			this.page_number = page_number;
			await this.getAllScenarios();
		},
		async changePageNumber(num_pages) {
			this.page_number = 1;
			this.items_per_page = num_pages === 0 ? this.items_per_page = this.count : num_pages;
			await this.getAllScenarios();
		},
		filterScenariosByMethod(type, flags) {
			let value = []
			for (let obj of flags){
				value.push(obj.type)
			}
			if (type=='invocation'){
				this.selected_values_invocation = flags
				this.flags = value;
			}else{
				this.selected_values_subset = flags
				this.subset_flags = value;
			}
			this.page_number = 1;
		},
		async changeOrderByRule(order_by) {
			this.page_number = 1;
			let obj = {};
			this.order_by = this.pagination.descending ? 'descending' : 'ascending'
			obj['scenarios_ordering'] = this.order_by_options_scenario[order_by.order_by];
			obj['scenarios_order_type'] = this.order_by
			await ReportsAPI.updateUserScenarioOrdering(obj)
			await this.getAllScenarios()
		},
		async searchByName(){
			if (this.search_by_name.length >= 3 || this.search_by_name.length == 0){
				this.page_number = 1;
				await this.getAllScenarios()
			}
		},
		async changeOrderType(order_type) {
			this.order_type = 'descending' ? order_type : 'ascending'
			this.page_number = 1;
			await this.changeOrderByRule(this.order_by);
		},
		async deleteScenario(scenario_id) {
			await ListApi.deleteScenario(scenario_id);
			this.$q.notify(`The template was successfully deleted.`)
			await this.getAllScenarios();
		},
		async copyScenario(scenario_id, copy_is_real, copy_as_subset) {
			this.$q.loading.show({
				delay: 400,
			})
			await ListApi.copyScenario(this.active_tab, copy_is_real, scenario_id, copy_as_subset);
			let active_tab = ''
			if (copy_is_real){
				active_tab = 'real'
			}else{
				active_tab = 'test'
			}
			if (active_tab != this.active_tab){
				this.changed_tab = active_tab
			}
			this.$q.notify(`The template was successfully copied.`)
			await this.openTab(active_tab)
			this.$q.loading.hide()
		},
		async unblockScenario(scenario_id) {
			await ListApi.unblockScenario(scenario_id);
			this.$q.notify(`The template was successfully unblocked.`)
			await this.getAllScenarios();
		},
		getMessage(message) {
			this.message = message;
		},
		displayExportMessage(message){
			this.$q.notify({
				timeout: 6700,
				message: message,
				actions: [
					{
						label: 'Dismiss',
						color: 'primary',
					},
				],
			})
		},
		async removeItem(item, type){
			if (type == 'invocation'){
				let index = this.selected_values_invocation.indexOf(item);
				if (index > -1) {
					this.selected_values_invocation.splice(index, 1);
					this.filterScenariosByMethod('invocation', this.selected_values_invocation)
					await this.getAllScenarios()
				}
			}else if(type == 'subset'){
				let index = this.selected_values_subset.indexOf(item);
				if (index > -1) {
					this.selected_values_subset.splice(index, 1);
					this.filterScenariosByMethod('subset', this.selected_values_subset)
					await this.getAllScenarios()
				}
			}
		},
		onRequest(props) {
			const { rowsPerPage, page, rowsNumber} = props.pagination
			if (props.pagination.sortBy != this.pagination.sortBy ||
				props.pagination.descending != this.pagination.descending){
				this.page_number = 1
				this.pagination.sortBy = props.pagination.sortBy
				this.pagination.descending = props.pagination.descending
				let order_type = this.pagination.descending == false ? 'descending' : 'ascending'
				let data = {
					'order_type': order_type,
					'order_by': this.pagination.sortBy,
				}
				this.changeOrderByRule(data)
			}else if (rowsPerPage || page){
				this.page_number = 1
				this.pagination.rowsPerPage = rowsPerPage
				this.pagination.rowsNumber = rowsNumber
				this.changePageNumber(this.pagination.rowsPerPage)
			}
		},
		async checkScheduledSessions(){
			let response = await ListApi.checkScheduledSessions(this.active_tab);
			this.$q.loading.show({
				delay: 400,
			})
			this.show_delete_dialog = false
			if (response.has_scheduled_session){
				this.show_warning = true
				this.$q.loading.hide()
				return
			}
			this.$q.notify(`The templates were successfully deleted.`)
			await this.getAllScenarios();
			this.$q.loading.hide()
		},
		async deleteAllTemplates(){
			this.show_delete_dialog = false
			this.show_warning = false
			this.$q.loading.show({
				delay: 400,
			})
			await ListApi.deleteAllScenarios(this.active_tab);
			this.$q.notify(`The templates were successfully deleted.`)
			await this.getAllScenarios();
			this.$q.loading.hide()
		}
	},
	async created() {
		if (this.logged_user) {
			this.preferred_timezone = this.logged_user.preferred_timezone;
			this.preferred_timezone_preview = "Timezone: " + this.preferred_timezone;
		}
		let has_phone_invoke_permission = this.current_CG.root_customer_group_permissions.indexOf('Phone invoke') >= 0;
		let has_email_invoke_permission = this.current_CG.root_customer_group_permissions.indexOf('Email invoke') >= 0
		let has_incident_manager_permission = this.current_CG.root_customer_group_permissions
			.filter(permission => permission.indexOf('Incident Manager') >= 0)
			.length > 0;
		let has_subset_permission = this.current_CG.root_customer_group_permissions.indexOf('Allow Subset') >= 0;
		this.has_filters = has_phone_invoke_permission || has_email_invoke_permission || has_incident_manager_permission || has_subset_permission
		await this.getAllScenarios();
	},
	watch: {
		scenarios: {
			deep: true,
			handler() {
				this.scenarios_ready = !!Object.keys(this.scenarios).length;
			}
		},
		is_schedule_message_running: function(){
			if(this.is_schedule_message_running){
				this.$q.loading.show({
					delay: 400,
				})
			}else{
				this.$q.loading.hide()
			}
		}
	},
};
</script>
<style lang="scss">
	a{
		text-decoration: none;
	}
	.wrap{
		display: flex;
		flex-wrap: wrap!important;
	}
	.field-fab-wrapper {
		position: sticky;
		bottom: 20px;
		z-index: 5;
		width: fit-content;
		margin: 10px auto;
	}
</style>
